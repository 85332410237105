<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "NotificationSettings.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "NotifierSettingList",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "NotifierSettingList",
        requestUrl: String.format("/Lcdp-NotifierSettingList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "notifierSettingId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/NotificationSetting/Edit/`,
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-NotifierSettingDelete",
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "NotificationSettingEdit",
              params: {
                notifierSettingId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "NotificationSettings.DisplayName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "displayName",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "NotificationSettings.UserName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "userName",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "NotificationSettings.NotifierType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "notifierTypeName",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "NotificationSettings.IsBeingUsedForSystemEmail",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isBeingUsedForSystemEmail",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>
